import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api/api";
import { useUserContext } from "../../../api/userContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./searchUsers.css";

function AdminUserSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setSelectedUserPhoneNumber } = useUserContext();

  const handleSearch = async () => {
    if (!searchTerm) {
      setErrorMessage("Please enter a phone number.");
      return;
    }

    setErrorMessage("");

    try {
      const result = await api.adminGetBottles(searchTerm);

      if (result.success) {
        const formattedForURL = searchTerm.trim();
        setSelectedUserPhoneNumber(formattedForURL);
        navigate(`/admin/bottles?phoneNumber=${formattedForURL}`);
      } else {
        setErrorMessage("User not found.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while searching for users.");
    }
  };

  return (
    <div className="search-container">
      <h2>Search Users</h2>
      <PhoneInput
        country={"us"}
        value={searchTerm}
        onChange={(phone) => setSearchTerm(phone)}
        placeholder="Enter Mobile No."
        containerClass="phone-container"
      />
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <button onClick={handleSearch}>Go</button>
    </div>
  );
}

export default AdminUserSearch;
