import React from "react";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../assets/images/plus.png";
import SearchIcon from "../../assets/images/search.png";
import { useUserContext } from "../../api/userContext";
import { useAuth } from "../../api/authContext";
import "../../styles/header.css";

function Header() {
  const navigate = useNavigate();
  const { selectedUserPhoneNumber } = useUserContext();

  const { logout } = useAuth();

  const handleLogoutClick = async () => {
    const result = await logout();
    if (result.success) {
      navigate("/");
    }
  };

  return (
    <div className="header-container">
      <div className="header-left">
        <button
          onClick={() =>
            navigate(`/admin/bottles?phoneNumber=${selectedUserPhoneNumber}`)
          }
          className="admin-buttons"
        >
          Have Fun Bugis
        </button>
      </div>
      <div className="header-middle">
        <p>ADMIN</p>
      </div>
      <div className="header-right">
        <div className="header-button">
          <button
            onClick={() => navigate("/admin/searchUsers")}
            className="admin-buttons"
          >
            <img src={SearchIcon} alt="Search" />
          </button>
        </div>
        <div className="header-button">
          <button
            onClick={() => navigate("/admin/create-bottle")}
            className="admin-buttons"
          >
            <img src={PlusIcon} alt="Create Bottle" />
          </button>
        </div>
        <div className="header-button">
          <button onClick={handleLogoutClick}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default Header;
