import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./api/authContext";
import { UserProvider } from "./api/userContext";
import LoadingScreen from "./components/shared-content/loading/loading";
import "./App.css";

// Eager load components
import GuestHomePage from "./components/guest-content/guestHomePage";
import Login from "./components/guest-content/login";
import Header from "./components/user-content/header";
import Nav from "./components/user-content/nav";
import AdminHeader from "./components/admin-content/header";
import AdminNav from "./components/admin-content/nav";
import AdminSearch from "./components/admin-content/search-users/searchUsers";
import AdminCreateBottle from "./components/admin-content/createBottle";

// Lazy load components
const Home = lazy(() => import("./components/user-content/home"));
const ClubContent = lazy(() => import("./components/user-content/clubContent"));
const AdminHome = lazy(() => import("./components/admin-content/home"));
const AdminClubContent = lazy(() =>
  import("./components/admin-content/clubContent")
);

function App() {
  const { currentUser } = useAuth();

  return (
    <Suspense fallback={<LoadingScreen />}>
      {!currentUser ? (
        <Routes>
          <Route path="/" element={<GuestHomePage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <div className="app-container">
          {currentUser?.role === "user" && (
            <>
              <Header />
              <Nav />
              <div className="main-container">
                <Routes>
                  <Route path="/bottles" element={<Home />} />
                  <Route path="/club/:clubId" element={<ClubContent />} />
                </Routes>
              </div>
            </>
          )}

          {currentUser?.role === "admin" && (
            <>
              <AdminHeader />
              <AdminNav />
              <div className="main-container">
                <Routes>
                  <Route path="/admin/bottles" element={<AdminHome />} />
                  <Route path="/admin/searchUsers" element={<AdminSearch />} />
                  <Route
                    path="/admin/create-bottle"
                    element={<AdminCreateBottle />}
                  />
                  <Route
                    path="/admin/club/:clubId"
                    element={<AdminClubContent />}
                  />
                </Routes>
              </div>
            </>
          )}
        </div>
      )}
    </Suspense>
  );
}

export default function AppWrapper() {
  return (
    <UserProvider>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </UserProvider>
  );
}
