import React from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../assets/images/home.png";
import ProfileIcon from "../../assets/images/user.png";
import "../../styles/nav.css";

function Nav() {
  const navigate = useNavigate();

  return (
    <div className="nav-container">
      <div className="profile-button">
        <button onClick={() => navigate("/bottles")}>
          <img src={HomeIcon} alt="Home" />
        </button>
      </div>
      <div className="profile-button">
        <button onClick={() => navigate("/profile")}>
          <img src={ProfileIcon} alt="Profile" />
        </button>
      </div>
    </div>
  );
}

export default Nav;
