import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/authContext";
import "../../styles/header.css";

function Header() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogoutClick = async () => {
    const result = await logout();
    if (result.success) {
      navigate("/");
    }
  };

  return (
    <div className="header-container">
      <div className="header-left">
        <button
          onClick={() => {
            navigate("/bottles");
          }}
        >
          Have Fun Bugis
        </button>
      </div>
      <div className="header-middle"></div>
      <div className="header-right">
        <button onClick={handleLogoutClick}>Logout</button>
      </div>
    </div>
  );
}

export default Header;
