import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [selectedUserPhoneNumber, setSelectedUserPhoneNumber] = useState(null);

  return (
    <UserContext.Provider
      value={{
        selectedUserPhoneNumber,
        setSelectedUserPhoneNumber,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
