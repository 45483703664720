import React, { useState, createContext, useContext, useEffect } from "react";
import { api } from "./api";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getLoggedInStatus = async () => {
    setLoading(true);
    const result = await api.getLoggedIn();

    if (result.success) {
      const roleResult = await api.getRole();
      if (roleResult.success) {
        setCurrentUser({
          role: roleResult.role,
        });
      } else {
        throw new Error(roleResult.errorMessage);
      }
    } else {
      setCurrentUser(null);
    }

    setLoading(false);
  };

  useEffect(() => {
    getLoggedInStatus();
  }, []);

  const login = async (phoneNumber) => {
    setLoading(true);
    try {
      const result = await api.login(phoneNumber);
      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      return { success: false, message: "Login failed." };
    }
  };

  const verifyLoginCode = async (phoneNumber, loginCode) => {
    setLoading(true);
    try {
      const result = await api.verifyLoginCode(phoneNumber, loginCode);
      if (result.success) {
        const roleResult = await api.getRole();
        if (roleResult.success) {
          setCurrentUser({
            role: roleResult.role,
          });
          setLoading(false);
          return { success: true, role: roleResult.role };
        }
      }
      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      return { success: false, message: "Verification failed." };
    }
  };

  const logout = async () => {
    setLoading(true);
    const result = await api.logout();
    if (result.success) {
      setCurrentUser(null);
    }
    setLoading(false);
    return result;
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, login, verifyLoginCode, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
