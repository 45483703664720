import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/authContext";
import PhoneInput from "react-phone-input-2";
import "./forms.css";

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loginCodeRequested, setLoginCodeRequested] = useState(false);
  const [loginCode, setLoginCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedPhoneNumber = "+" + phoneNumber;
    if (!loginCodeRequested) {
      try {
        const result = await auth.login(formattedPhoneNumber);
        if (result.success) {
          setErrorMessage("");
          setLoginCodeRequested(true);
        } else {
          setErrorMessage(result.message || "Login failed. Please try again.");
        }
      } catch (error) {
        setErrorMessage("An error occurred during login. Please try again.");
      }
    } else {
      try {
        const result = await auth.verifyLoginCode(
          formattedPhoneNumber,
          loginCode
        );
        if (result.success) {
          const role = result.role;
          if (role === "admin") {
            navigate("/admin/searchUsers");
          } else {
            navigate("/bottles");
          }
        } else {
          setErrorMessage(result.message || "Login failed. Please try again.");
        }
      } catch (error) {
        setErrorMessage("An error occurred during login. Please try again.");
      }
    }
  };

  return (
    <div className="guestHome-container">
      <div className="guestHome-image">
        <div className="login-container">
          <h2>Welcome Back</h2>
          <PhoneInput
            country={"us"}
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder="Mobile No."
            inputClass="login-input"
            buttonClass="phone-button"
            containerClass="phone-container"
            enableAreaCodes={true}
            enableAreaCodeStretch={true}
            disableCountryCode={false} // Disable user input on country code
          />

          {loginCodeRequested && (
            <input
              type="text"
              value={loginCode}
              onChange={(e) => setLoginCode(e.target.value)}
              placeholder="Enter Login Code"
              className="login-input"
            />
          )}

          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button onClick={handleSubmit} className="main-button">
            {loginCodeRequested ? "VERIFY CODE" : "LOGIN"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
