import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Login from "./login";
import "./guestHomePage.css";

function GuestHomePage() {
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = location.pathname === "/login";

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleCloseClick = (e) => {
    if (e.target.classList.contains("login-overlay")) {
      navigate("/");
    }
  };

  return (
    <div className="guestHome-container">
      <div className="guestHome-image">
        <div className="welcome-message">
          <h1>WELCOME TO</h1>
          <p className="logo">Have Fun Bugis</p>
        </div>
        <div className="welcome-bottom">
          <button className="main-login-button" onClick={handleLoginClick}>
            LOGIN
          </button>
          <div className="privacy-links">
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </div>
        </div>
      </div>

      {isLogin && (
        <div className="login-overlay" onClick={handleCloseClick}>
          <div className="login-pullup visible">
            <Login />
          </div>
        </div>
      )}
    </div>
  );
}

export default GuestHomePage;
