import React, { useState } from "react";
import { api } from "../../api/api";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../styles/createBottle.css";

function CreateBottle() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [club, setClub] = useState("");
  const [capacity, setCapacity] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await api.createBottle(phoneNumber, name, club, capacity);

      if (result.success) {
        navigate(`/admin/bottles?phoneNumber=${phoneNumber}`);
      } else {
        setMessage(
          result.message || "An error occurred while creating the bottle."
        );
      }
    } catch (error) {
      setMessage("An error occurred during bottle creation.");
    }
  };

  return (
    <div className="createBottle-container">
      <form className="create-bottle-form" onSubmit={handleSubmit}>
        <div className="create-bottle-form-group">
          <label htmlFor="phone-number">Phone Number:</label>
          <PhoneInput
            country={"us"}
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            placeholder="Enter Mobile No."
          />
        </div>
        <div className="create-bottle-form-group">
          <label htmlFor="name">Bottle Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="create-bottle-form-group">
          <label htmlFor="club">Club Name:</label>
          <input
            type="text"
            id="club"
            name="club"
            value={club}
            onChange={(e) => setClub(e.target.value)}
          />
        </div>
        <div className="create-bottle-form-group">
          <label htmlFor="capacity">Capacity:</label>
          <input
            type="text"
            id="capacity"
            name="capacity"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
          />
        </div>
        <button type="submit">Create Bottle</button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
}

export default CreateBottle;
