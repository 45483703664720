import React from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/images/search.png";
import HomeIcon from "../../assets/images/home.png";
import PlusIcon from "../../assets/images/plus.png";
import ProfileIcon from "../../assets/images/user.png";
import { useUserContext } from "../../api/userContext";
import "../../styles/nav.css";

function Nav() {
  const navigate = useNavigate();
  const { selectedUserPhoneNumber } = useUserContext();

  return (
    <div className="nav-container">
      <div className="profile-button">
        <button
          onClick={() =>
            navigate(`/admin/bottles?phoneNumber=${selectedUserPhoneNumber}`)
          }
        >
          <img src={HomeIcon} alt="Home" />
        </button>
      </div>
      <div className="profile-button">
        <button onClick={() => navigate("/admin/searchUsers")}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>
      <div className="profile-button">
        <button onClick={() => navigate("/admin/create-bottle")}>
          <img src={PlusIcon} alt="Create Bottle" />
        </button>
      </div>
      <div className="profile-button">
        <button onClick={() => navigate("/admin")}>
          <img src={ProfileIcon} alt="Admin Profile" />
        </button>
      </div>
    </div>
  );
}

export default Nav;
